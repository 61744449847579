<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <filelist
          :editer="true"
          :isform="false"
          :doc_type="0"
          :title="'Documents'"
        >
        </filelist>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  components: {
    filelist: () => import("@/components/FileList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },

  data: () => ({
    loading: false,
    klist: 400,
    km: 500,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    selitem: {},
  }),
  watch: {},
  computed: {},
  async created() {},

  mounted() {},

  methods: {
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
  },
};
</script>
